import { useEffect, useState } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import axios from "../axios";
import { useStore } from "../components/designer/Store/zustandStore";
import { useNavigate, useLocation } from "react-router-dom";
import fileDownload from "js-file-download";
import { TOOL_MODE } from "../components/designer/Constants";

import {
  selectionQueryKeys,
  configuratorQueryKeys,
  designerQueryKeys,
  technicalQueryKeys,
  chartQueryKeys,
  userQueryKeys,
  projectQueryKeys,
  pricingQueryKeys,
  adminQueryKeys,
} from "../react-query/queryConstants";
import {
  HomeIcon,
  BriefcaseIcon,
  BuildingLibraryIcon,
} from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import { userStore, projectStore, generalStore } from "../stores/userStore";
import { groupBy, mapValues, omit, isEmpty, result } from "lodash";

const selectionInformationObject = {
  Volume: 0,
  Pressure: 0,
  ExternalStaticPressure: 0,
  Canopy: "",
  FrameType: "",
  FrameWidth: 0,
  BaseHeight: 0,
  SideOfAccess: 0,
  FanStyle: "",
  FanOptions: "",
  OutletOptions: "",
  ModelId: "DEV-K",
  SelectedVentilationUnitId: "DEV-K-01",
};

export function useModal() {
  const [modal, updateModal] = useState({
    title: "",
    content: "",
  });

  const [openModal, updateOpenModal] = useState(false);

  // useEffect(() => {
  //   updateOpenModal((prevOpenModal) => prevOpenModal)
  // },[])

  // const updateModal = (modal) => {
  // queryClient.setQueryData(modalQueryKeys.modal, modal);
  // };

  const showModal = (m) => {
    updateModal(m);
    updateOpenModal(true);
    //queryClient.invalidateQueries(["updateOpenModal"]);
  };

  // const updateOpenModal = () => {
  //   updateopenModal(true);
  //   return true;
  // }

  // const { data: isModalVisible = false} = useQuery(["updateOpenModal"],updateOpenModal,    {
  //   refetchOnMount: false,
  // });

  return { openModal, updateOpenModal, showModal };
}

export function useConfigurator(instanceIds) {
  const queryClient = useQueryClient();
  const [hideLoading, setHideLoading] = generalStore((state) => [
    state.hideLoading,
    state.setHideLoading,
  ]);
  const { calculateUnit } = useAHUDesignerManager();

  // const{ventilationInformation} = useConfigurationFan()

  // const configurationModels = [
  //   {
  //     configurationModelId: "DEV-K",
  //     configurationModelDescription: "",
  //     configurationModelIconPath: "",
  //   },
  // ];

  const getSelectionInformation = () => {
    return (
      queryClient.getQueryData(selectionQueryKeys.selectionInformation) ??
      selectionInformationObject
    );
  };

  const fallback = [];

  const { data: selectedUnitModelName = "" } = useQuery(
    [configuratorQueryKeys.GetSelectedUnitModelName],
    async ({ signal }) => {
      //  if (instanceIds || useStore.getState().selectedComponentInstance) {
      return await axios.get(`Configuration/GetSelectedUnitModelName`, {
        signal: signal,
      });
    },
    {
      select: (data) => data?.data,
      refetchOnMount: false,
      // enabled: false,
      //cacheTime:0
    }
  );
  const { data: referrer = "" } = useQuery(
    [configuratorQueryKeys.getReferrer],
    async ({ signal }) => {
      let instanceIds = ["BOM"];
      //  if (instanceIds || useStore.getState().selectedComponentInstance) {
      return await axios.get(`Configuration/GetReferrer`, { signal: signal });
    },
    {
      select: (data) => data?.data,
      refetchOnMount: false,
    }
  );

  const { data: configurationInstances = fallback } = useQuery(
    [
      configuratorQueryKeys.getConfigurationInstances,
      selectedUnitModelName,
      useStore.getState().selectedComponentInstance?.join(","),
    ],
    async ({ signal }) => {
      //  if (instanceIds || useStore.getState().selectedComponentInstance) {
      setHideLoading(false);
      let instances =
        useStore.getState().selectedComponentInstance?.length == 0 ||
        useStore.getState().selectedComponentInstance == null
          ? "DesignDetails"
          : useStore.getState().selectedComponentInstance?.join(",");
      return await axios.get(
        // `Configuration/GetInstances/${getSelectionInformation().ModelId}/${useStore.getState().selectedComponentInstance?.join(",")}`
        `Configuration/GetInstances/${
          getSelectionInformation().ModelId
        }/${instances}`,
        { signal: signal }
        // `Configuration/GetInstances`,
        // {
        //   params: {
        //     ModelId: getSelectionInformation().ModelId,
        //     configurationInstanceIds: useStore.getState().selectedComponentIds.join(","),
        //   },
        // }
      );
      // } else {
      //   return null;
      // }
    },
    {
      select: (data) => data?.data,
      refetchOnMount: false,
      //enabled: !isEmpty(useStore.getState().selectedComponentInstance?.join(",")),
      onSuccess: () => {
        setHideLoading(true);
      },
      // cacheTime: 0,
      // retry: false
    }
  );

  const { data: selectedConfigurationInstances = fallback } = useQuery(
    [
      configuratorQueryKeys.getConfigurationInstances,
      selectedUnitModelName,
      "Selected",
      instanceIds,
    ],
    async ({ signal }) => {
      setHideLoading(false);
      //  if (instanceIds || useStore.getState().selectedComponentInstance) {
      return await axios.get(
        `Configuration/GetInstances/${
          getSelectionInformation().ModelId
        }/${instanceIds}`,
        { signal: signal }
        // `Configuration/GetInstances`,
        // {
        //   params: {
        //     ModelId: getSelectionInformation().ModelId,
        //     configurationInstanceIds: useStore.getState().selectedComponentIds.join(","),
        //   },
        // }
      );
      // } else {
      //   return null;
      // }
    },
    {
      select: (data) => data?.data,
      //refetchOnMount: false,
      enabled: !isEmpty(instanceIds),
      // cacheTime: 0,
    }
  );

  const { data: BOMConfigurationInstances = fallback } = useQuery(
    [configuratorQueryKeys.getConfigurationInstances, "DEV-K", "BOM"],
    async ({ signal }) => {
      let instanceIds = ["BOM"];
      //  if (instanceIds || useStore.getState().selectedComponentInstance) {
      return await axios.get(
        `Configuration/GetInstances/${
          getSelectionInformation().ModelId
        }/${instanceIds}`,
        { signal: signal }
      );
    },
    {
      select: (data) => data?.data,
      // refetchOnMount: false,
      //enabled: false
    }
  );

  const { mutate: selectVentilationUnit } = useMutation(
    [configuratorQueryKeys.selectVentilationUnit],
    async ({ ventilationUnitId }) => {
      setHideLoading(false);
      return await axios.post(
        `Configuration/SelectVentilationUnit/${ventilationUnitId}`,
        {}
      );
    },
    {
      onSuccess: (result) => {
        // queryClient.invalidateQueries([
        //   configuratorQueryKeys.GetConfigurationInstance,
        //   "DesignDetails",
        // ]);
        queryClient.resetQueries({
          queryKey: [
            configuratorQueryKeys.GetConfigurationInstance,
            "DesignDetails",
          ],
        });
        queryClient.invalidateQueries([
          selectionQueryKeys.getVentilationInformation,
        ]);
        calculateUnit();
      },
    }
  );
  const { mutate: selectProjectVentilationUnit } = useMutation(
    [configuratorQueryKeys.selectProjectVentilationUnit],
    async ({ ventilationUnitId }) => {
      setHideLoading(false);
      return await axios.post(
        `Configuration/SelectProjectVentilationUnit/${ventilationUnitId}`,
        {}
      );
    },
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries([
          configuratorQueryKeys.GetConfigurationInstance,
          "DesignDetails",
        ]);
        queryClient.invalidateQueries([
          selectionQueryKeys.getVentilationInformation,
        ]);
        calculateUnit(false);
      },
    }
  );

  return {
    configurationInstances,
    selectedConfigurationInstances,
    BOMConfigurationInstances,
    selectedUnitModelName,
    selectVentilationUnit,
    selectProjectVentilationUnit,
    referrer,
  };
}

export function useConfigurationFan(selectedUUID) {
  const { refetchPricing } = usePricing();
  const queryClient = useQueryClient();
  const { calculateUnit } = useAHUDesignerManager();

  const { data: fans = null } = useQuery(
    [configuratorQueryKeys.getSelectedUnitFans, selectedUUID],
    async () => {
      if (selectedUUID) {
        console.log("fanitems is not null");
        return await axios.get(
          `Configuration/GetSelectedUnitFans/${selectedUUID[0]}`
        );
      } else {
        console.log("fan item is null");
        return null;
      }
    },
    {
      select: (data) => data?.data,
      refetchOnMount: false,
      cacheTime: 0,
    }
  );

  const { mutate: selectFan } = useMutation(
    async (data) =>
      await axios.post(
        "/Configuration/SelectFan",
        {},
        {
          params: {
            ventilationUnitId: data.ventilationUnitId,
            fanId: data.fanModelName,
            referrer: "Selector",
          },
        }
      ),
    {
      onSuccess: (result) => {
        calculateUnit();
        queryClient.invalidateQueries([
          configuratorQueryKeys.getSelectedUnitFans,
        ]);
        //queryClient.invalidateQueries([pricingQueryKeys.getPricingList]);
        refetchPricing();
      },
      onError: (result) => {},
    }
  );

  const { mutate: selectDrive } = useMutation(
    async (data) =>
      await axios.post(
        "/Configuration/SelectDrive",
        {},
        {
          params: {
            ventilationUnitId: data.ventilationUnitId,
            fanId: data.fanModelName,
            driveId: data.driveId,
            referrer: "Selector",
          },
        }
      ),
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries([
          configuratorQueryKeys.getSelectedUnitFans,
          selectedUUID,
        ]);

        queryClient.invalidateQueries([
          technicalQueryKeys.getTechnicalSections,
        ]);
        // queryClient.invalidateQueries([pricingQueryKeys.getPricingList]);
        refetchPricing();
      },
    }
  );

  return {
    fans,
    selectFan,
    selectDrive,
  };
}

export function useConfigurationInstance(configurationInstanceId = "") {
  const fallback = null;

  const {
    data: configurationInstance = fallback,
    refetch: refetchConfigurationInstance,
  } = useQuery(
    [configuratorQueryKeys.GetConfigurationInstance, configurationInstanceId],
    async ({ signal }) => {
      return await axios.get(
        `Configuration/GetConfigurationInstance/${configurationInstanceId}`,
        { signal: signal }
      );
    },
    {
      select: (data) => data.data,
      //refetchOnMount: false,
      enabled: !isEmpty(configurationInstanceId),
      //cacheTime:0
    }
  );

  return {
    refetchConfigurationInstance,
    configurationInstance,
  };
}

export function useConfigurationInstanceBOMId(configurationInstanceId = "") {
  const { data: configurationItemBOMId = "" } = useQuery(
    [
      configuratorQueryKeys.GetConfigurationInstanceBOMId,
      configurationInstanceId,
    ],
    async () => {
      return await axios.get(
        `Configuration/GetConfigurationInstanceBOMId/${configurationInstanceId}`
      );
    },
    {
      select: (data) => data.data,
      //refetchOnMount: false,
    }
  );

  return { configurationItemBOMId };
}

export function useUpdateConfiguration(cv, ci) {
  const { resetSelection } = useSelection();
  const { calculateUnit } = useAHUDesignerManager();
  const { refetchConfigurationInstance } = useConfigurationInstance();
  const [setToolMode] = useStore((state) => [state.setToolMode]);
  const [hideLoading, setHideLoading] = generalStore((state) => [
    state.hideLoading,
    state.setHideLoading,
  ]);

  const queryClient = useQueryClient();
  const key = cv?.key;
  const { mutate } = useMutation(
    async (cv, ci) => {
      if (key == "PRODUCT") {
        resetSelection();
      }
      setHideLoading(false);
      setToolMode(TOOL_MODE.DISABLED);
      return await axios.post("/Configuration/UpdateAvailability", cv);
    },
    {
      onSuccess: (result) => {
        //setHideLoading(true);
        //const configuratorInstance = result.data;
        calculateUnit();
        refetchConfigurationInstance();
        setToolMode(TOOL_MODE.SELECTION);
        // queryClient.invalidateQueries([configuratorQueryKeys.GetConfigurationInstance, result.data.instanceId]);
        // queryClient.invalidateQueries([configuratorQueryKeys.GetConfigurationInstance]);
      },
      onError: (error) => {
        setToolMode(TOOL_MODE.SELECTION);
        setHideLoading(true);
      },
    }
  );

  return mutate;
}

export function useSelection() {
  const queryClient = useQueryClient();
  const [hideLoading, setHideLoading] = generalStore((state) => [
    state.hideLoading,
    state.setHideLoading,
  ]);
  const { calculateUnit } = useAHUDesignerManager();

  // const { calculateUnit } = useAHUDesignerManager();
  // const [ventilationInformation, setVentilationInformation] = useState();

  const { data: ventilationInformation } = useQuery(
    [selectionQueryKeys.getVentilationInformation],
    async ({ signal }) => {
      return await axios.get(`Configuration/GetVentilationInformation`, {
        signal: signal,
      });
    },
    {
      select: (data) => data.data,
      // onSuccess:(data) =>  queryClient.setQueryData(selectionQueryKeys.ventilationInformation,data),
    }
  );

  // useEffect(() => {
  //   setVentilationInformation(ventilationInformationData);
  // }, [ventilationInformationData]);

  //const [selectionStore, setSelectionStore] = useState(queryClient.getQueryData([selectionQueryKeys.selectionStore]) ?? selectionInformationObject);

  const [velocityPressure, setVelocityPressure] = useState(0);
  const fallback = [];

  let enableQuery = false;

  // const selectProducts = (selectionInformation) => {
  //   updateSelectionInformation(selectionInformation);
  //   enableQuery = true;
  //   //refetch();
  // };

  const updateSelectionInformation = (selectionInformation) => {
    queryClient.setQueryData(
      selectionQueryKeys.selectionInformation,
      selectionInformation
    );
    //refetch();
  };

  const getSelectionInformation = () => {
    return (
      queryClient.getQueryData(selectionQueryKeys.selectionInformation) ??
      selectionInformationObject
    );
  };

  // const { data: selectionResults = fallback, refetch } = useQuery(
  //   [
  //     selectionQueryKeys.selectProducts,
  //     getSelectionInformation().Volume,
  //     getSelectionInformation().Pressure,
  //   ],
  //   async () => {
  //     return await axios.post(
  //       "/selection/SelectProducts",
  //       {},
  //       {
  //         params: {
  //           velocityPressure: getSelectionInformation().Volume,
  //           pressure: getSelectionInformation().Pressure,
  //         },
  //       } // for now
  //     );
  //   },
  //   {
  //     select: (data) => data.data,
  //     refetchOnMount: false,
  //     //enabled: false, // disable the query from automatically running
  //   }
  // );

  const { mutate: getUnits } = useMutation(
    async (vInfo) => {
      setHideLoading(false);
      return await axios.post("/Configuration/GetUnits", vInfo);
    },
    {
      onSuccess: (result) => {
        //setHideLoading(true)
        queryClient.invalidateQueries([
          selectionQueryKeys.getVentilationInformation,
        ]);
        calculateUnit();
        // queryClient.invalidateQueries([configuratorQueryKeys.GetConfigurationInstance]);
        // queryClient.invalidateQueries([designerQueryKeys.getAHUDesignerManaager]);
        // queryClient.invalidateQueries([configuratorQueryKeys.GetSelectedUnitModelName]);
        // queryClient.invalidateQueries([technicalQueryKeys.getTechnicalSections]);
        // queryClient.invalidateQueries([pricingQueryKeys.getPricingList]);
        // queryClient.invalidateQueries([chartQueryKeys.getChartModel]);
      },
    }
  );

  const { mutate: resetSelection } = useMutation(
    [configuratorQueryKeys.resetSelection],
    async () => {
      setHideLoading(false);
      return await axios.post("Configuration/ResetSelection", {});
    },
    {
      onSuccess: (result) => {
        calculateUnit();
        // getUnits(ventilationInformation);
        // queryClient.invalidateQueries([configuratorQueryKeys.getReferrer]);
        // queryClient.invalidateQueries([configuratorQueryKeys.GetSelectedUnitModelName]);
        // queryClient.invalidateQueries([configuratorQueryKeys.GetConfigurationInstance, "DesignDetails"]);
      },
    }
  );

  return {
    velocityPressure,
    // selectProducts,
    //selectionResults,
    //selectionFields,
    getSelectionInformation,
    selectionInformationObject,
    updateSelectionInformation,
    ventilationInformation,
    //setVentilationInformation,
    getUnits,
    resetSelection,
  };
}

export function useAHUDesignerManager() {
  const { refetchPricing } = usePricing();
  const [selectedUUID, setSelectedUUID] = useStore((state) => [
    state.selectedUUID,
    state.setSelectedUUID,
  ]);
  const [hideLoading, setHideLoading] = generalStore((state) => [
    state.hideLoading,
    state.setHideLoading,
  ]);

  //const {selectedUnitModelName} = useConfigurator();

  const fallback = null;

  const queryClient = useQueryClient();

  //const { getSelectionInformation } = useSelection();

  //const ventilationUnitId = getSelectionInformation().SelectedVentilationUnitId;

  const { data: ahuDesignerManager = fallback, refetch } = useQuery(
    [designerQueryKeys.getAHUDesignerManaager],
    async ({ signal }) => {
      // if (ventilationUnitId) {
      //setHideLoading(false);
      return await axios.get(
        //    `AHUDesigner/GetAHUDesignerManager/${ventilationUnitId}`,
        //   // `AHUDesigner/GetAHUDesignerManager`,
        //   {
        //     params: { ventilationUnitId: ventilationUnitId },
        //   }
        // );
        // // } else {
        // //   return null;
        // // }
        `AHUDesigner/GetAHUDesignerManager`,
        { signal: signal }
      );
    },
    {
      select: (data) => data.data,
      //enabled:false,//;// !isEmpty(selectedUnitModelName),
      refetchOnMount: false,
      onSuccess: () => {
        setHideLoading(true);
      },
      //enabled: false, // disable the query from automatically running
    }
  );

  const { mutate: addComponent } = useMutation(
    async (data) => {
      setHideLoading(false);
      return await axios.post(
        "/AHUDesigner/AddComponent",
        // {
        //   attachToComponentId: id,
        //   configurationItemId: ci,
        //   side:side
        // }
        null,
        {
          params: {
            attachToComponentId: data.id,
            configurationItemId: data.ci,
            side: data.side,
          },
        }
      );
    },
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries([
          configuratorQueryKeys.getConfigurationInstances,
          "DEV-K",
          "BOM",
        ]);
        calculateUnit(true);
        // queryClient.invalidateQueries([
        //   configuratorQueryKeys.GetConfigurationInstance,
        // ]);
        //queryClient.invalidateQueries([designerQueryKeys.getAHUDesignerManaager]);
      },
    }
  );

  const { mutate: calculateUnit } = useMutation(
    async (refreshPrice) =>{
      setHideLoading(false);
      return await axios.post("/Configuration/CalculateUnit/", null, {
        params: { refreshPrice: refreshPrice },
      })},
    {
      onSuccess: (response, refreshPrice) => {
        setHideLoading(true);
        const result = response.data;

        if (!result.isSuccess && !isEmpty(result.error)) {
          toast(result.error);
        }

        refetchPricing();

        queryClient.invalidateQueries([
          designerQueryKeys.getAHUDesignerManaager,
        ]);
        queryClient.invalidateQueries([
          technicalQueryKeys.getTechnicalSections,
        ]);
        queryClient.invalidateQueries([chartQueryKeys.getChartModel]);
        //queryClient.invalidateQueries([pricingQueryKeys.getPricingList]);
        queryClient.invalidateQueries([configuratorQueryKeys.getReferrer]);
        queryClient.invalidateQueries([
          configuratorQueryKeys.GetSelectedUnitModelName,
        ]);

        queryClient.resetQueries([
          configuratorQueryKeys.GetConfigurationInstance,
        ]);
        // queryClient.invalidateQueries([
        //   configuratorQueryKeys.GetConfigurationInstance,
        // ]);
      },
      onError: (result) => {},
    }
  );

  const { mutate: deleteComponent } = useMutation(
    async () => {
      setHideLoading(false);
      return await axios.post("/AHUDesigner/DeleteComponent", null, {
        params: { stringComponentIds: JSON.stringify(selectedUUID) },
      });
    },
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries([
          configuratorQueryKeys.getConfigurationInstances,
          "DEV-K",
          "BOM",
        ]);
        calculateUnit(true);
      },
    }
  );

  const { mutate: makeSection } = useMutation(
    async (data) => {
      setHideLoading(false);
      return await axios.post("/AHUDesigner/MakeSection", null, {
        params: { stringComponentIds: JSON.stringify(data) },
      });
    },
    {
      onSuccess: (result) => {
        setSelectedUUID([]);
        calculateUnit(true);
        // queryClient.invalidateQueries([designerQueryKeys.getAHUDesignerManaager]);
        // queryClient.invalidateQueries([technicalQueryKeys.getTechnicalSections]);
        // queryClient.invalidateQueries([chartQueryKeys.getChartModel]);
      },
    }
  );

  const { mutate: removeSection } = useMutation(
    async () => {
      setHideLoading(false);
      return await axios.post("/AHUDesigner/RemoveSection", null, {
        params: { stringComponentIds: JSON.stringify(selectedUUID) },
      });
    },
    {
      onSuccess: (result) => {
        setSelectedUUID([]);
        calculateUnit(true);
        // queryClient.invalidateQueries([designerQueryKeys.getAHUDesignerManaager]);
        // queryClient.invalidateQueries([technicalQueryKeys.getTechnicalSections]);
        // queryClient.invalidateQueries([chartQueryKeys.getChartModel]);
      },
    }
  );

  return {
    ahuDesignerManager,
    addComponent,
    calculateUnit,
    deleteComponent,
    makeSection,
    removeSection,
  };
}

export function useNav() {
  const location = useLocation();

  const [navigations, setNavigations] = useState([
    {
      name: "Selection",
      href: "/selection",
      icon: HomeIcon,
      current: location.pathname === "/selection",
    },
    {
      name: "Project",
      href: "/project",
      icon: BriefcaseIcon,
      current: location.pathname === "/project",
    },
    {
      name: "Admin",
      href: "/admin/users",
      icon: BuildingLibraryIcon,
      current: location.pathname === "/admin/users",
      adminOnly: true,
    },
  ]);

  const updateCurrentNavigation = (key) => {
    location.pathname = `/${key}`;
    const updatedNavigations = [...navigations];
    for (let navigation of updatedNavigations) {
      navigation.current = navigation.name === key;
    }
    setNavigations(updatedNavigations);
  };

  return { navigations, updateCurrentNavigation };
}

export function usePrint() {
  // const { data } = useQuery(
  //   [printQueryKeys.print],
  //   async (printRequest) => {
  //     return await axios.post(
  //       "/Print/Print",
  //       {},
  //       {
  //         params: {
  //           printRequest: printRequest,
  //         },
  //       }
  //     );
  //   },
  //   {
  //     select: (data) => data.data,
  //     refetchOnMount: false,
  //     //enabled: false, // disable the query from automatically running
  //   }
  // );

  const { mutate: printSummary } = useMutation(
    async (printRequest) => await axios.post("/print/print", printRequest),
    {
      onSuccess: (response) => {
        fileDownload(response.data, "Project Summary" + ".pdf");
        //setPrinting(false);

        //queryClient.invalidateQueries([projectQueryKeys.getProjectItems]);
      },
    }
  );

  return {
    printSummary,
  };
}

export function useTechnical() {
  const { selectedUnitModelName } = useConfigurator();
  const [hideLoading, setHideLoading] = generalStore((state) => [
    state.hideLoading,
    state.setHideLoading,
  ]);

  const { data: technicalSections = null } = useQuery(
    [technicalQueryKeys.getTechnicalSections],
    async () => {
      //setHideLoading(true);
      return await axios.get(`Technical/GetTechnicalSections`);
    },
    {
      select: (data) => data?.data,
      refetchOnMount: true,
      //cacheTime: 0,
      enabled: !isEmpty(selectedUnitModelName),
      onSuccess: () => {
        //setHideLoading(false);
      },
    }
  );

  function convertTechnicalSectionItems(technicalSection) {
    let rowItems = [];
    mapValues(groupBy(technicalSection.items, "rowId"), (clist) => {
      clist.map((c) => omit(c, "rowId"));
      rowItems.push(clist);
    });
    return rowItems;
  }

  return {
    technicalSections,
    convertTechnicalSectionItems,
  };
}

export function useChart() {
  const { data: chartModel = null } = useQuery(
    [chartQueryKeys.getChartModel],
    async () => {
      return await axios.get(`Technical/GetChartModel`);
    },
    {
      select: (data) => data?.data,
      refetchOnMount: false,
      //cacheTime: 0,
    }
  );

  return {
    chartModel,
  };
}

export function useUser() {
  const [user, setUser, isAuthenticated, setIsAuthenticated] = userStore(
    (state) => [
      state.user,
      state.setUser,
      state.isAuthenticated,
      state.setIsAuthenticated,
    ]
  );
  const [refreshTokenTimeout, setRefreshTokenTimeout] = generalStore(
    (state) => [state.refreshTokenTimeout, state.setRefreshTokenTimeout]
  );
  const { setProject } = useProject();
  const navigate = useNavigate();

  const prevLocation = useLocation();

  const queryClient = useQueryClient();

  useEffect(() => {
    setIsAuthenticated(localStorage.getItem("user") != null);
  }, [user]);

  const refreshToken = () => {
    axios
      .post("/User/refresh-token")
      .then((response) => {
        localStorage.setItem("user", JSON.stringify(response.data ?? ""));
        setUser(response.data);
        startRefreshTokenTimer(response.data);
      })
      .catch(() => {
        logout();
      });
  };

  const startRefreshTokenTimer = (user) => {
    // parse json object from base64 encoded jwt token

    const jwtBase64 = user.jwtToken.split(".")[1];
    const jwtToken = JSON.parse(atob(jwtBase64));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    setRefreshTokenTimeout(setTimeout(refreshToken, timeout));
  };

  const stopRefreshTokenTimer = () => {
    clearTimeout(refreshTokenTimeout);
  };

  const { mutate: login } = useMutation(
    async (loginDetails) =>
      await axios.post("/User/authenticate", loginDetails).then((result) => {
        if (result) {
          const user = result.data;
          // update pinia state

          // store user details and jwt in local storage to keep user logged in between page refreshes
          localStorage.setItem("user", JSON.stringify(user ?? ""));

          setUser(user);

          //queryClient.setQueryData(userQueryKeys.user, user);

          // redirect to previous url or default to home page
          navigate("/selection");
          // router.push(returnUrl.value || "/");
        }
      }),
    {
      onSuccess: (result) => {},
    }
  );

  const { mutate: registerUser } = useMutation(
    async (registerDetails) =>
      await axios.post("/User/register", registerDetails),
    {
      onSuccess: (result) => {
        // update pinia state

        // store user details and jwt in local storage to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(user ?? ""));
        setUser(result.data);

        // redirect to previous url or default to home page
        // router.push(returnUrl.value || "/");
        navigate("/login");

        toast("Thank your for registering. Please check your email to verify.");
      },
    }
  );

  const { mutate: verifyEmail } = useMutation(
    async (token) =>
      await axios.post("/User/verify-email", {
        token: token,
      }),
    {
      onSuccess: (result) => {
        setUser(result);
        // update state
        startRefreshTokenTimer(user);

        // store user details and jwt in local storage to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(user ?? ""));

        // redirect to previous url or default to home page
        // router.push(returnUrl.value || "/");
        navigate("/login");

        toast("Email or password is incorrect");
      },
    }
  );

  // const logout = () => {
  //   localStorage.removeItem("user");
  //   queryClient.setQueryData(userQueryKeys.user, null);
  //   setUser(null);
  //   stopRefreshTokenTimer();
  //   navigate("/login");
  // };

  const { mutate: updateUser } = useMutation(
    async (updateRequest) =>
      await axios.post("/User/update-user", updateRequest),
    {
      onSuccess: (result) => {
        user = result.data;
        // update pinia state

        // store user details and jwt in local storage to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(user ?? ""));

        // redirect to previous url or default to home page
        // router.push(returnUrl.value || "/");
        navigate("/login");

        toast("Account updated successfully.");
      },
    }
  );

  const { mutate: logout } = useMutation(
    async () => await axios.post("/User/logout"),
    {
      onSuccess: () => {
        localStorage.removeItem("user");
        setProject(null);
        queryClient.setQueryData(userQueryKeys.user, null);
        setUser(null);
        stopRefreshTokenTimer();
        queryClient.clear();
        navigate("/login");
      },
    }
  );

  const { mutate: forgotPassword } = useMutation(
    async (forogtPasswordRequest) =>
      await axios.post("/User/forgot-password", forogtPasswordRequest),
    {
      onSuccess: () => {
        navigate("/login");
        toast("Reset password email sent");
        // localStorage.removeItem("user");
        // queryClient.setQueryData(userQueryKeys.user, null);
        // setUser(null);
        // stopRefreshTokenTimer();
        // navigate("/login");
      },
    }
  );
  const { mutate: resetPassword } = useMutation(
    async (resetPasswordRequest) =>
      await axios.post("/User/reset-password", resetPasswordRequest),
    {
      onSuccess: () => {
        // queryClient.invalidateQueries([adminQueryKeys.getUserList]);
        navigate("/login");
        toast("Password successfully reset.");

        // localStorage.removeItem("user");
        // queryClient.setQueryData(userQueryKeys.user, null);
        // setUser(null);
        // stopRefreshTokenTimer();
        // navigate("/login");
      },
    }
  );

  return {
    login,
    registerUser,
    verifyEmail,
    user,
    isAuthenticated,
    logout,
    updateUser,
    refreshToken,
    forgotPassword,
    resetPassword,
    logoutAxios,
  };
}
export async function logoutAxios() {
  //const { setProject } = useProject();
  //const navigate = useNavigate();

  const [user, setUser, isAuthenticated, setIsAuthenticated] = userStore(
    (state) => [
      state.user,
      state.setUser,
      state.isAuthenticated,
      state.setIsAuthenticated,
    ]
  );

  await axios.post("/User/logout").then(() => {
    localStorage.removeItem("user");
    //setProject(null);
    //queryClient.setQueryData(userQueryKeys.user, null);
    setUser(null);
    //stopRefreshTokenTimer();
    //queryClient.clear();
    // navigate("/login");
  });
}

export function useProject(locationCode) {
  const queryClient = useQueryClient();
  const { resetSelection } = useSelection();
  const { referrer } = useConfigurator();

  const [project, setProject] = projectStore((state) => [
    state.project,
    state.setProject,
  ]);
  const [setHideLoading] = generalStore((state) => [state.setHideLoading]);

  const [user] = userStore((state) => [state.user]);

  const fallback = [];

  const { mutate: refreshProjectPrice } = useMutation(
    async () => {
      setHideLoading(false);
      await axios.post("/project/refresh-price");
    },
    {
      onSuccess: (result) => {
        setHideLoading(true);
        toast("Price successfully refreshed");
        queryClient.invalidateQueries([projectQueryKeys.getProjectItems]);
      },
      onError: () => {
        setHideLoading(true);
      },
    }
  );

  const { data: projectLocations = fallback } = useQuery(
    [projectQueryKeys.getProjectLocations],
    async ({ signal }) => {
      return await axios.get(`Project/get-project-locations/GULF`, {
        signal: signal,
      });
    },
    {
      select: (data) => data?.data,
      enabled: user != null,
      //refetchOnMount: false,
      // cacheTime: 0,
      // retry: false
    }
  );

  const { data: cities = fallback } = useQuery(
    [projectQueryKeys.getProjectCities, locationCode],
    async () => {
      return await axios.get(`Project/get-project-cities/${locationCode}`);
    },
    {
      select: (data) => data?.data,
      enabled: locationCode != null,
      //refetchOnMount: false,
      // cacheTime: 0,
      // retry: false
    }
  );

  const { data: projectStatus = fallback } = useQuery(
    [projectQueryKeys.getProjectStatus],
    async () => {
      return await axios.get(`Project/get-project-status`);
    },
    {
      select: (data) => data?.data,
      enabled: user != null,
    }
  );

  const { data: poStatus = fallback } = useQuery(
    [projectQueryKeys.getPOStatus],
    async () => {
      return await axios.get(`Project/get-po-status`);
    },
    {
      select: (data) => data?.data,
      enabled: user != null,
    }
  );

  const { data: lostReasons = fallback } = useQuery(
    [projectQueryKeys.getReasonForLost],
    async () => {
      return await axios.get(`Project/get-lost-reasons`);
    },
    {
      select: (data) => data?.data,
      enabled: user != null,
    }
  );

  const { data: competitors = fallback } = useQuery(
    [projectQueryKeys.getCompetitor],
    async () => {
      return await axios.get(`Project/get-competitor`);
    },
    {
      select: (data) => data?.data,
      enabled: user != null,
    }
  );

  const { data: consultants = fallback } = useQuery(
    [projectQueryKeys.getConsultant],
    async () => {
      return await axios.get(`Project/get-consultant`);
    },
    {
      select: (data) => data?.data,
      enabled: user != null,
    }
  );

  const { data: serviceTypes = fallback } = useQuery(
    [projectQueryKeys.getServiceType],
    async () => {
      return await axios.get(`Project/get-service-type`);
    },
    {
      select: (data) => data?.data,
      enabled: user != null,
    }
  );

  const { data: discountLimit } = useQuery(
    [projectQueryKeys.getDiscountLimit, locationCode],
    async ({ signal }) =>
      await axios.get(`project/get-discount-limit/${locationCode}`, { signal }),
    {
      select: (result) => result?.data?.value,
      enabled: locationCode != null && locationCode != "",
    }
  );

  // const { mutate: updateProject } = useMutation(
  //   async (projectDetailsRequest) => {
  //     setHideLoading(false);
  //     return await axios.post("/Project/update-project-details", projectDetailsRequest);
  //   },
  //   {
  //     onSuccess: (result) => {
  //       queryClient.invalidateQueries([projectQueryKeys.getCurrentProject]);
  //     },
  //   }
  // );

  const { data: currenProject, refetch: getCurrentProject } = useQuery(
    [projectQueryKeys.getCurrentProject],
    async () => {
      return await axios.get(`Project/get-current-project`);
    },
    {
      select: (data) => data?.data,
      enabled: user != null,
      //refetchOnMount: false,
      // cacheTime: 0,
      // retry: false
    }
  );

  const { data: projectLists = fallback } = useQuery(
    [projectQueryKeys.getProjectLists],
    async () => {
      return await axios.get(`Project/get-projects`);
    },
    {
      select: (data) => data?.data,
      enabled: user != null,
      //refetchOnMount: false,
      // cacheTime: 0,
      // retry: false
    }
  );

  useEffect(() => {
    setProject(currenProject);
  }, [currenProject]);

  const { mutate: addToProject } = useMutation(
    async (addItemRequest) => {
      setHideLoading(false);
      return await axios.post("/Project/add-item", addItemRequest);
    },
    {
      onSuccess: (result) => {
        setHideLoading(true);
        //getCurrentProject();
        //queryClient.invalidateQueries([projectQueryKeys.getProjectItems]);
        getProjectItems();
        toast("Unit successfully added to project");
      },
    }
  );

  const { data: projectItemsSummary, refetch: getProjectItems } = useQuery(
    [projectQueryKeys.getProjectItems],
    async ({ signal }) => {
      return await axios.get("/Project/get-project-items", { signal: signal });
    },
    {
      select: (data) => data?.data,
      //refetchOnMount: false,
      // cacheTime: 0,
      // retry: false
    }
  );
  // const { data: projectItemsData, refetch: getProjectItems } = useQuery(
  //   [projectQueryKeys.getProjectItems],
  //   async ({ signal }) => {
  //     return await axios.get("/Project/get-project-items", { signal: signal });
  //   },
  //   {
  //     select: (data) => data?.data,
  //     //refetchOnMount: false,
  //     // cacheTime: 0,
  //     // retry: false
  //   }
  // );

  // const [projectItems, setProjectItems] = useState();

  // useEffect(() => {
  //   setProjectItems(projectItemsData);
  // }, [projectItemsData]);

  const { mutate: saveProject } = useMutation(
    async (saveProjectRequest) => {
      setHideLoading(false);
      return await axios.post("/Project/save", saveProjectRequest);
    },
    {
      onSuccess: (result) => {
        setHideLoading(true);
        queryClient.invalidateQueries([projectQueryKeys.getCurrentProject]);
        queryClient.invalidateQueries([projectQueryKeys.getProjectLists]);
        toast("Project successfully saved");
      },
    }
  );

  const checkProjectDiscountWithLocation = async (lc) => {
    let pass = false;
    await axios
      .post("/Project/check-project-discount", null, {
        params: { locationCode: lc },
      })
      .then((data) => {
        pass = data.data;
      });

    return pass;
  };

  // const { mutate: checkProjectDiscountWithLocation } = useMutation(
  //   async (locationCode) => {
  //     setHideLoading(false);
  //     return await axios.post("/Project/check-project-discount", null, {
  //       params: { locationCode: locationCode },
  //     });
  //   },
  //   {
  //     onSuccess: (result) => {
  //        setHideLoading(true);
  //       // queryClient.invalidateQueries([projectQueryKeys.getCurrentProject]);
  //       // queryClient.invalidateQueries([projectQueryKeys.getProjectLists]);
  //       // toast("Project successfully saved");
  //     },
  //   }
  // );

  const { mutate: openProject } = useMutation(
    async (saveProjectRequest) =>
    {
      setHideLoading(false);
      return await axios.post("/Project/open-project", saveProjectRequest)
    },
    {
      onSuccess: (result) => {
        if (referrer == "Project") {
          resetSelection();
        }
        queryClient.invalidateQueries([projectQueryKeys.getCurrentProject]);
        queryClient.invalidateQueries([projectQueryKeys.getProjectItems]);
        setHideLoading(true);
      },
    }
  );

  const { mutate: newProject } = useMutation(
    async () => await axios.get("/Project/new"),
    {
      onSuccess: (result) => {
        if (referrer == "Project") {
          resetSelection();
        }
        queryClient.invalidateQueries([projectQueryKeys.getCurrentProject]);
        queryClient.setQueryData(
          [projectQueryKeys.getProjectItems],
          result?.data?.value
        );
        toast("New project created");
      },
    }
  );

  const { mutate: updateProjectItem } = useMutation(
    async (updateProjectItemRequest) => {
      setHideLoading(false);
      return await axios.post(
        "/Project/update-project-item",
        updateProjectItemRequest
      );
    },
    {
      onSuccess: (result) => {
        setHideLoading(true);
        queryClient.invalidateQueries([projectQueryKeys.getCurrentProject]);
        queryClient.invalidateQueries([projectQueryKeys.getProjectItems]);
        // queryClient.invalidateQueries([technicalQueryKeys.getTechnicalSections]);

        //queryClient.setQueryData([projectQueryKeys.getProjectItems], result?.data?.value);
        toast("Project item successfully updated");
      },
    }
  );
  const { mutate: deleteProjectItem } = useMutation(
    async (deleteProjectItemRequest) => {
      setHideLoading(false);
      return await axios.post("/Project/remove-item", deleteProjectItemRequest);
    },
    {
      onSuccess: (result) => {
        setHideLoading(true);
        //queryClient.invalidateQueries([projectQueryKeys.getCurrentProject]);
        queryClient.invalidateQueries([projectQueryKeys.getProjectItems]);

        // queryClient.setQueryData([projectQueryKeys.getProjectItems], result?.data?.value);
        toast("Project item successfully removed");
      },
    }
  );

  const { mutate: deleteProject } = useMutation(
    async (deleteProjectRequest) =>
      await axios.post("/Project/delete", deleteProjectRequest),
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries([projectQueryKeys.getProjectLists]);
        // queryClient.setQueryData([projectQueryKeys.getProjectItems], result?.data?.value);
        toast("Project successfully deleted");
      },
    }
  );
  const { mutate: cloneProjectItem } = useMutation(
    async (cloneProjectItemRequest) => {
      setHideLoading(false);

      await axios.post("/Project/clone-project-item", cloneProjectItemRequest);
    },
    {
      onSuccess: (result) => {
        setHideLoading(true);
        queryClient.invalidateQueries([projectQueryKeys.getProjectItems]);
        // queryClient.setQueryData([projectQueryKeys.getProjectItems], result?.data?.value);
        toast("Item successfully copied");
      },
    }
  );

  // const { mutate: updateProjectItemField } = useMutation(
  //   async (updateProjectItemFieldRequest) => {
  //    setHideLoading(false);
  //     setProjectItems(
  //       projectItems.map((pi) => {
  //         if (pi.guid == updateProjectItemFieldRequest.resultItem.guid) {
  //           pi.reference = updateProjectItemFieldRequest.resultItem.reference;
  //         }
  //         return pi;
  //       })
  //     );
  //     await axios.post(
  //       "/Project/update-project-item-field",
  //       updateProjectItemFieldRequest
  //     );
  //   },
  //   {
  //     onSuccess: (result) => {
  //      setHideLoading(true);
  //       queryClient.invalidateQueries([projectQueryKeys.getProjectItems]);
  //       queryClient.invalidateQueries([
  //         technicalQueryKeys.getTechnicalSections,
  //       ]);
  //     },
  //   }
  // );

  const updateProjectItemField = async (updateProjectItemFieldRequest) => {
    await axios.post(
      "/Project/update-project-item-field",
      updateProjectItemFieldRequest
    );

    // setProjectItems(
    //   projectItems.map((pi) => {
    //     if (pi.guid == updateProjectItemFieldRequest.resultItem.guid) {
    //       pi.reference = updateProjectItemFieldRequest.resultItem.reference;
    //       pi.quantity = updateProjectItemFieldRequest.resultItem.quantity;
    //       pi.totalPrice =
    //         pi.totalPrice * updateProjectItemFieldRequest.resultItem.quantity;
    //     }
    //     return pi;
    //   })
    // );
    queryClient.invalidateQueries([technicalQueryKeys.getTechnicalSections]);
  };

  const { mutate: updateProjectDetails } = useMutation(
    async (updateProjectDetailsRequest) => {
      await axios.post(
        "/Project/update-project-details",
        updateProjectDetailsRequest
      );
    },
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries([projectQueryKeys.getCurrentProject]);
      },
    }
  );
  const { mutate: printSummary } = useMutation(
    async (printRequest) => await axios.post("/print/print", printRequest),
    {
      onSuccess: (response) => {
        fileDownload(response.data, "Project Summary" + ".pdf");
        //setPrinting(false);

        //queryClient.invalidateQueries([projectQueryKeys.getProjectItems]);
      },
    }
  );
  const { mutate: updateProjectDiscounts } = useMutation(
    async (updateProjectDiscountRequest) => {
      setHideLoading(false);
      await axios.post(
        "/project/update-project-discounts",
        updateProjectDiscountRequest
      );
    },
    {
      onSuccess: (response) => {
        setHideLoading(true);
        // setProject(prevState =>({
        //   ...prevState,
        //   discount:updateProjectDiscountRequest.discount,
        //   salesMargin:updateProjectDiscountRequest.salesOfficeMargin
        // }))

        queryClient.invalidateQueries([projectQueryKeys.getCurrentProject]);
        queryClient.invalidateQueries([projectQueryKeys.getProjectItems]);
      },
    }
  );
  const { mutate: addCustomItem } = useMutation(
    async (addCustomItemRequest) => {
      setHideLoading(false);
      await axios.post("/project/add-custom-item", addCustomItemRequest);
    },
    {
      onSuccess: (response) => {
        setHideLoading(true);
        // queryClient.invalidateQueries([projectQueryKeys.getCurrentProject]);
        queryClient.invalidateQueries([projectQueryKeys.getProjectItems]);
      },
    }
  );

  // const { data: projectLists = fallback } = useQuery(
  //   [configuratorQueryKeys.getProjectLists],
  //   async () => {
  //     return await axios.get("/Project/get-projects");
  //   },
  //   {
  //     select: (data) => data?.data,
  //     //refetchOnMount: false,
  //     // cacheTime: 0,
  //     // retry: false
  //   }
  // );

  return {
    project,
    setProject,
    addToProject,
    // projectItems,
    saveProject,
    projectLists,
    openProject,
    newProject,
    deleteProject,
    updateProjectItem,
    projectLocations,
    cities,
    projectStatus,
    poStatus,
    lostReasons,
    competitors,
    consultants,
    serviceTypes,
    deleteProjectItem,
    cloneProjectItem,
    updateProjectItemField,
    printSummary,
    updateProjectDiscounts,
    addCustomItem,
    updateProjectDetails,
    refreshProjectPrice,
    discountLimit,
    checkProjectDiscountWithLocation,
    projectItemsSummary
  };
}

export function usePricing(ventilationUnitId) {
  const [hideLoading, setHideLoading] = generalStore((state) => [
    state.hideLoading,
    state.setHideLoading,
  ]);

  const { data: pricing, refetch: refetchPricing } = useQuery(
    [pricingQueryKeys.getPricingList, ventilationUnitId],
    async ({ signal }) => {
      return await axios.get(
        `Configuration/get-pricing-list/${null}/Selector`,
        { signal: signal }
      );
    },
    {
      select: (data) => data?.data,
      // enabled:
      //   ventilationUnitId != null &&
      //   ventilationUnitId != "" &&
      //   ventilationUnitId != undefined,
      onSuccess: () => {
        //setHideLoading(false);
      },
    }
  );

  return { pricing, refetchPricing };
}

export function useAdmin() {
  const queryClient = useQueryClient();

  const [user] = userStore((state) => [state.user]);

  const [userList, setUserList] = useState();

  const { data: userListData } = useQuery(
    [adminQueryKeys.getUserList],
    async () => {
      return await axios.get("User/get-all-user");
    },
    {
      select: (result) => result.data,
      enabled: user?.userGroupId == 2,
    }
  );
  useEffect(() => {
    setUserList(userListData);
  }, [userListData]);

  const { mutate: approveUser } = useMutation(
    async (approveRequest) =>
      await axios.post("/User/approve-user", approveRequest),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([adminQueryKeys.getUserList]);

        toast("User successfully approved.");

        // localStorage.removeItem("user");
        // queryClient.setQueryData(userQueryKeys.user, null);
        // setUser(null);
        // stopRefreshTokenTimer();
        // navigate("/login");
      },
    }
  );
  const { mutate: revokeUser } = useMutation(
    async (revokeRequest) =>
      await axios.post("/User/revoke-user", revokeRequest),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([adminQueryKeys.getUserList]);

        toast("User successfully revoked.");

        // localStorage.removeItem("user");
        // queryClient.setQueryData(userQueryKeys.user, null);
        // setUser(null);
        // stopRefreshTokenTimer();
        // navigate("/login");
      },
    }
  );
  const { mutate: deleteUser } = useMutation(
    async (deleteRequest) =>
      await axios.post("/User/delete-user", deleteRequest),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([adminQueryKeys.getUserList]);

        toast("User successfully removed.");

        // localStorage.removeItem("user");
        // queryClient.setQueryData(userQueryKeys.user, null);
        // setUser(null);
        // stopRefreshTokenTimer();
        // navigate("/login");
      },
    }
  );
  const { mutate: updateUserByAdmin } = useMutation(
    async (UpdateRequest) =>
      await axios.post("/User/update-user-by-admin", UpdateRequest),
    {
      onSuccess: () => {
        // queryClient.invalidateQueries([adminQueryKeys.getUserList]);

        toast("User successfully updated.");

        // localStorage.removeItem("user");
        // queryClient.setQueryData(userQueryKeys.user, null);
        // setUser(null);
        // stopRefreshTokenTimer();
        // navigate("/login");
      },
    }
  );

  return {
    userList,
    setUserList,
    approveUser,
    revokeUser,
    deleteUser,
    updateUserByAdmin,
  };
}
